import axios from "axios";
import { navigate } from "gatsby";
// @ts-ignore
import NProgress from "nprogress"

// @ts-ignore
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { notifications } from "@repo/ui/components";

function getCookie(cname: string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

var requests = 0;

export const axiosCommon = axios.create({
    baseURL: process.env.GATSBY_API_URL,
    //timeout: 1000,
    headers: {
        //'X-Custom-Header': 'foobar' 
    },
});

axiosCommon.interceptors.request.use(function (config) {
    // Do something before request is sent
    const cookie = getCookie('user')
    if (cookie) {
        const json = JSON.parse(cookie)
        if (!json?.jwt) {
            const decoded = jwtDecode(json?.jwt);
            if (decoded && dayjs(decoded.exp).diff(dayjs()) < 0) {
                navigate('/signin')
                return Promise.reject('Unauthorized');
            }
        }
        config.headers["Authorization"] = `Bearer ${json?.jwt}`;
    }

    //console.log(config)
    if (requests === 0) {
        NProgress.start();
    }
    requests++;

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

axiosCommon.interceptors.response.use(res => {
    requests--;
    if (requests === 0) {
        NProgress.done();
    }
    return res;
}, error => {
    handleError(error)
    //return error;
    NProgress.done();
})

axios.interceptors.response.use(res => res, error => {
    handleError(error)
    //return error;
})

let lastMessage = "";
function handleError(error: any) {
    let message = "";

    if (error?.response?.status === 401) {
        window.location.href = '/signin'
    }

    if (error.response) message = error.response?.data?.title || error.response?.data?.message;
    else if (error.request) message = error.message;
    else message = error.message;

    if (message === lastMessage) return;
    if (message == null || message == "") {
        message = "An error occurred while processing data! 🤥";
    }
    notifications.show({
        color: 'red',
        title: 'Some thing went wrong!',
        message,
    })
    lastMessage = "";
}

export function removeEmptyField(obj: any) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null && v != undefined)) as any;
}

export const FakeResponse = { data: null, isSuccess: false, status: '' }

export async function axiosMeduverseGet(path: string, filter: Object) {
    const params = new URLSearchParams(removeEmptyField(filter)).toString()
    const response = await axiosCommon.get(`/manage/localization/getresourcestring?${params}`);
    //console.log(response);
    return response;
}

export function useMeduverseQuery(path: string, filter: Object) {
    async function getData() {
        const params = new URLSearchParams(removeEmptyField(filter)).toString()
        const response = await axiosCommon.get(`${path}?${params}`);
        //console.log(response);
        return response;
    }

    return useQuery([path, filter], getData, { refetchOnWindowFocus: false })
}