// src/theme.ts
import { Button, MantineThemeOverride, createTheme } from '@repo/ui/components';

export const theme: MantineThemeOverride = createTheme({
    fontFamily: `Be Vietnam Pro`,
    primaryColor: 'blue',
    defaultRadius: 'md',
    fontSizes: {
        // xs: '0.75rem',
        // sm: '1rem',
        // md: '1.2rem',
        // lg: '1.5rem',
        // xl: '1.8rem',
    },
    components: {
        Button: Button.extend({
            styles: {
                //root: { borderBottomWidth: 2.5, borderBottomColor: 'gray' },
            },
        }),
    }
});