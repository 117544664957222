import * as React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { navigate } from "gatsby";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { Role } from "../widgets/users/constants";
import { notifications } from "@repo/ui/components";
import { axiosCommon } from "../../axiosConfigs";

function deleteCookie(name: string) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export class SigninProps {
    identifier: string = ''
    password: string = ''
    remember: boolean = false
    redirectUrl?: string = '/'
}

export class AuthContextProps {
    userInfo: any | undefined
    signin: ((params: SigninProps) => void) | undefined
    signout: (() => void) | undefined;
}

const AuthContext = createContext<AuthContextProps>(new AuthContextProps());

export function AuthProvider({ children }: any) {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [userInfo, setUserInfo] = useState<any>();

    const signin = (params: SigninProps) => {
        const { identifier, password, remember, redirectUrl } = params
        axiosCommon.post(`/api/auth/local?populate=role`, { identifier, password }, {
            baseURL: process.env.GATSBY_API_URL,
        }).then((x) => {
            console.log(x)
            if (!x) return;

            setCookie('user', x.data)
            if (redirectUrl) navigate(redirectUrl)
        })
    }

    // const signin = (params: SigninProps) => {
    //     const { identifier, password, remember, redirectUrl } = params
    //     const user = JSON.stringify({ "jwt": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiaWF0IjoxNzA5NzQzNzU5LCJleHAiOjE3MTIzMzU3NTl9.1aa2CAj0xfQMOcyd6wgBG_toVYZaOcR0N6IqSajlMKw", "user": { "id": 2, "username": "leader", "email": "leader@gmail.com", "provider": "local", "confirmed": true, "blocked": false, "fullname": "Huyền Trang", "yearOfBirth": null, "identityNumber": null, "ethnic": null, "religion": null, "profession": null, "permanent": null, "joinedDate": null, "partisan": null, "coreMember": true, "fluctuations": null, "createdAt": "2024-03-04T10:37:27.047Z", "updatedAt": "2024-03-06T04:37:18.810Z" } })
    //     setCookie('user', user)
    //     if (redirectUrl) {
    //         // navigate(redirectUrl)
    //         location.href = process.env.GATSBY_API_DOMAIN as string;
    //     }
    // }

    const signout = () => {
        // console.log('deleteCookie')
        deleteCookie('user')
    };

    useEffect(() => {
        // console.log('cookies', cookies)
        if (cookies.user && cookies.user.jwt) {
            const decoded = jwtDecode(cookies.user.jwt);
            const diff = dayjs().diff(dayjs.unix(decoded.exp as number))

            axiosCommon.get(`/api/users/me?populate=role`).then((x) => {
                //console.log(x)
                if (!x) return;
                setUserInfo(x.data)
            })

            // setUserInfo({
            //     "id": 2,
            //     "username": "leader",
            //     "email": "leader@gmail.com",
            //     "provider": "local",
            //     "confirmed": true,
            //     "blocked": false,
            //     "fullname": "Huyền Trang",
            //     "yearOfBirth": null,
            //     "identityNumber": null,
            //     "ethnic": null,
            //     "religion": null,
            //     "profession": null,
            //     "permanent": null,
            //     "joinedDate": null,
            //     "partisan": null,
            //     "coreMember": true,
            //     "fluctuations": null,
            //     "createdAt": "2024-03-04T10:37:27.047Z",
            //     "updatedAt": "2024-03-06T04:37:18.810Z",
            //     "role": {
            //         "id": 3,
            //         "name": "Leader",
            //         "description": "Hội trưởng hội phụ nữ",
            //         "type": "leader",
            //         "createdAt": "2024-03-04T10:36:13.364Z",
            //         "updatedAt": "2024-03-04T11:19:23.041Z"
            //     }
            // })
        } else {
            setUserInfo(undefined)
            //navigate('/signin')
        }
    }, [cookies])

    return (
        <>
            <AuthContext.Provider value={{ userInfo, signin, signout }}>
                {children}
            </AuthContext.Provider>
        </>
    );
};

export default AuthContext;

export function useAuthContext(): AuthContextProps {
    const context = useContext(AuthContext);
    return context;
}

export function useInRole(role: Role): boolean {
    const { userInfo } = useAuthContext();
    // console.log('userInfo', userInfo?.role)
    const right = React.useMemo(() => {
        if (!userInfo) return false;
        return userInfo?.role?.type === role;
    }, [userInfo])

    //console.log(right)
    return right;
}

export function useCheckRoles(roles: Array<Role>): boolean {
    const { userInfo } = useAuthContext();
    //console.log('userInfo', userInfo)
    const right = React.useMemo(() => {
        if (!userInfo) return false;

        if (!roles) return false;

        return roles.includes(userInfo?.role?.type);
    }, [userInfo])

    //console.log(right)
    return right;
}
