import React from 'react';
import { theme } from './src/theme';
import { Providers } from '@repo/ui/providers';
import { AuthProvider } from './src/context/AuthProvider';
import "nprogress/nprogress.css";

export const wrapPageElement = ({ element }: any) => {
    return <Providers theme={theme}>
        <AuthProvider>
            {element}
        </AuthProvider>
    </Providers>;
};